import React, { useContext, useState  } from "react";
import { TextInput, Button } from "@3beehivetech/components";
import { 
  StyledMapBoxRetailers, 
  StyledMapBoxRetailersForm, 
  StyledMapBoxRetailersFormWrapper, 
  StyledMapBoxRetailersOverlay,
} from "./style";
import {
  StyledFormBlock,
  StyledFormCheckbox,
  StyledFormCheckboxInput,
  StyledFormCheckboxCheckmark,
} from "../../commons/Form";
import { DataContext } from "../../contexts/DataContext";
import useNewsletter from "../../hooks/useNewsletter";
import { toast } from "react-toastify";
import useTrack from "../../hooks/useTrack";
import useCountdown from "../../hooks/useCountdown";

const MapBoxTrees = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const counter = useCountdown("2022-11-25T09:00:00");
  const giorni = Math.trunc(counter/(1000 * 60 * 60 * 24));
  const ore = Math.trunc(counter/(1000 * 60 * 60)-giorni*24);
  const minuti = Math.trunc(counter/(1000 * 60)-giorni*24*60-ore*60);
  const secondi = Math.trunc(counter/(1000)-giorni*24*60*60-ore*60*60-minuti*60);
  //const [center, setCenter] = useState(null);
  //const [retailers, setRetailers] = useState([]);

  /*const { getStores } = useApi();
  useEffect(() => {
    getStores().then((res) => {
      setRetailers(res);
    });
  }, [getStores]);*/

  return (
    <StyledMapBoxRetailers>
      {showOverlay && (
        <>
          <StyledMapBoxRetailersFormWrapper>
            <NewsletterForm onSubmit={() => {
              //console.log("prova");
              setShowOverlay(false);
              /*if (lngLat) {
                setCenter(lngLat);
              }*/
            }}/>
          </StyledMapBoxRetailersFormWrapper>
          <StyledMapBoxRetailersOverlay />
        </>
      )}
      <div style="padding: 60px 5px 20px 5px;">
        <div style="font-size: 60px;">{giorni}<span style="font-size: 20px;">Giorni</span>  {ore}<span style="font-size: 20px;">Ore</span>  {minuti}<span style="font-size: 20px;">Minuti</span>  {secondi}<span style="font-size: 20px;">Secondi</span> </div>
      </div>
      {/*<MapDigitalApiary 
        center={center}
        zoom={10}
        expand={false}
        kpiVisibility={false}
        markers={retailers.map(e => {
          return {
            icon: "/2020/svg/marker_bee.svg",
            infowindow: `<strong>${e.name}</strong><br/>${e.address}<br/>${e.phone}`,
            lat: e.latitude,
            lng: e.longitude,
          };
        })}/>*/}
    </StyledMapBoxRetailers>
  );
};

const NewsletterForm = ({ onSubmit }) => {
  const { labels, pageLabels, languages } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const { addToNewsLetter } = useNewsletter();
  //const { getCityLngLat } = useGoogleApi();
  const { trackCustomEvent } = useTrack();
  //const { getStores } = useApi();

  const [formData, setFormData] = useState({
    email: null,
    city: null,
    acceptPrivacy: false,
  });

  const handleSubmit = async () => {
    toast.configure({ 
      style: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    });
    /* const cityLngLat = await getCityLngLat({ cityName: formData.city });
    if (!cityLngLat) {
      return toast.error(labels.CITY_INVALID, {
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    const retailers = await getStores(cityLngLat[1], cityLngLat[0]);
    
    if (retailers.length === 0) {
      console.log("alfa");
      onSubmit();
      toast.success(<div dangerouslySetInnerHTML={{ __html: labels.RESELLERS_NOT_AVAILABLE }}/>, {
        containerId: "Alert",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      });
    } else {
      console.log("beta");
      onSubmit(cityLngLat);
    }
    */
    const response = await addToNewsLetter({
      data: {
        email: formData.email,
        lang: currentLanguageCode,
        list_id: 143,
        attributes: {
          DEVICE: window.matchMedia("only screen and (max-width: 760px)").matches ? "Mobile" : "Desktop",
          BOX_CAMPAGNA: getAds(),
        },
      },
    });
    onSubmit();
    //console.log(response)
  };

  return (
    <StyledMapBoxRetailersForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
        trackCustomEvent({
          category: "BoxTrees",
          action: "click",
          label:  "Iscriviti!",
        });
      }}
    >
      <TextInput
        type="email"
        name="email"
        required
        placeholder={labels.MAIL_ADDRESS}
        onInput={(e) =>
          setFormData((prevFormData)=>({
            ...prevFormData,
            email: e.currentTarget.value, 
          }))
        }
      />
      <Button style="text-transform: uppercase; margin-top: 0.5rem;">
        {labels.SUBSCRIBE_LONG}
      </Button>
      <StyledFormBlock>
        <StyledFormCheckbox>
          {labels.ACCEPT} <a href="/documents/cookie_policy.pdf">{labels.ACCEPTOF}</a>
          <StyledFormCheckboxInput 
            required
          />
          <StyledFormCheckboxCheckmark />
        </StyledFormCheckbox>
      </StyledFormBlock>
    </StyledMapBoxRetailersForm>
  );
};

const getAds = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("fbclid")) {
    return "Facebook";
  }
  if (urlParams.has("gclid")) {
    return "Google";
  }
  if (urlParams.has("li_fat_id")) {
    return "Linkedin";
  }
  if (urlParams.has("msclkid")) {
    return "Microsoft";
  }
  return "Nessuna";
};

export default MapBoxTrees;
