import { useEffect, useRef, useState } from "react";

const useCountdown = (date) => {

  const toDate = new Date(date);
  const [count, setCount] = useState(0);
  const rawCount = useRef(0);

  useEffect(() => {
    const now = new Date();
    if (now >= toDate) {
      setCount(0);
      return;
    }

    const interval = setInterval(() => {
      rawCount.current = toDate - now;
      const newValue = Math.trunc(rawCount.current);
      setCount(newValue);
      
    }, 100);

    return () => clearInterval(interval);
  }, [count, rawCount, date]);

  return count;
};

export default useCountdown;

/*
const useCounter = (finalValue, milliseconds) => {
  const [count, setCount] = useState(0)

  const msInc = 10
  const steps = milliseconds / msInc
  const inc = finalValue / steps

  useEffect(() => {
    if (count >= finalValue) {
      setCount(finalValue)
      return
    }
    setTimeout(() => {
      const newValue = Math.ceil(count+inc)
      if (newValue >= finalValue) {
        setCount(finalValue)
      } else {
        setCount(newValue)
      }
    }, msInc)
  }, [count])

  return count
}*/
